import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export default i18n.use(initReactI18next)
    .init({
        lng: localStorage.getItem("DL"),
        fallbackLng: "de",
        interpolation: {
        escapeValue: false,
        },
        resources:{
            de: {
                translation: {
                    nav1: 'Über mich',
                    nav2: 'Fertigkeiten',
                    nav3: 'Kontakt',
                    menu: 'Menü',
                    language: 'Sprache',
                    name: 'Ich bin ara!',
                    mainSkill: 'Frontend-Entwickler',
                    about_s: 'Ich arbeite seit 8 Jahren im Bereich Software- und Softwaresystemmanagement, von der ersten Analyse bis zur Implementierung.',
                    about_m1: 'Ich habe über acht Jahre Berufserfahrung in der Softwareentwicklung. Analytisches Denken, Kundenorientierung und tiefes technisches Verständnis haben mich zu einer verantwortungsbewussten und engagierten Person gemacht.',
                    about_m2: 'Ich strebe stets danach, hochwertige Software zu entwerfen und zu entwickeln, und dafür setze ich das Lernen neuer Technologien zur Verbesserung meiner Leistung an oberste Stelle.',
                    slogan1: 'Idee',
                    slogan2: 'Kreativität',
                    slogan3: 'Umsetzung',
                    slogan4: 'Support',
                    inTouch1: 'Wenn Sie eine Idee haben, die Sie in eine Software umsetzen möchten, vereinbaren Sie einfach ein gemeinsames Beratungsgespräch, ich biete Ihnen attraktive Lösungen für den Ausbau Ihres Online-Geschäfts.',
                    inTouch2: 'Nehmen Sie Kontakt mit mir auf.',
                    inTouch_Subject: 'Thema',
                    inTouch_Email: 'Ihre E-Mail',
                    inTouch_Message: 'Schreibe Ihre Nachricht',
                    inTouch3: 'Schicken',
                    alertTitle1: 'Mail senden',
                    emailMsg1: 'Um eine E-Mail zu senden, müssen Sie sowohl die E-Mail-Adresse als auch das Nachrichtenfeld ausfüllen.',
                    emailMsg2: 'Die eingegebene E-Mail-Adresse ist ungültig. Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                    emailMsg3: 'Bitte schreiben Sie eine längere Nachricht (mindestens 10 Zeichen erforderlich).',
                    emailMsg4: 'Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank, dass Sie mir eine Nachricht geschickt haben. Ich werde versuchen, Ihre Nachricht so schnell wie möglich zu beantworten.',
                    emailMsg5: 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
                    langs1: 'Ich spreche ...',
                    langs2: 'Persisch',
                    langs3: 'Deutsch',
                    langs4: 'Englisch',
                    hobbies: 'Interessen und Hobbys',
                    toContact: 'kontaktieren',
                    interesting1: 'Schwimmen',
                    interesting2: 'Reisen',
                    interesting3: 'Lernen',
                    interesting4: 'Kochen',
                    interesting5: 'Fotografieren',
                    interesting6: 'Filmen',
                    interesting7: 'Fitnessstudio',
                    interesting8: 'Musik hören',
                    interesting9: 'Radfahren',
                }
            },
            en: {
                translation: {
                    nav1: 'About Me',
                    nav2: 'Skills',
                    nav3: 'Contact',
                    menu: 'Menu',
                    language: 'language',
                    name: 'My name is ara!',
                    mainSkill: 'Front-end Developer',
                    about_s: 'I have been working in the field of software and software systems management for 8 years, from initial analysis to implementation.',
                    about_m1: 'I have over eight years of professional experience in software development. Analytical thinking, customer focus, and deep technical understanding have made me a responsible and dedicated individual.',
                    about_m2: 'I am always striving for designing and developing high-quality software, and for this purpose, I prioritize learning new technologies to improve my performance.',
                    slogan1: 'Idea',
                    slogan2: 'Creativity',
                    slogan3: 'Implementation',
                    slogan4: 'Support',
                    inTouch1: 'If you have an idea that you would like to implement into software, simply arrange a consultation together. I will offer you attractive solutions for expanding your online business.',
                    inTouch2: 'Get in touch with me.',
                    inTouch_Subject: 'Subject',
                    inTouch_Email: 'Your Email',
                    inTouch_Message: 'Write Your Message',
                    inTouch3: 'Send',
                    alertTitle1: 'Send Mail',
                    emailMsg1: 'To send an email, you must fill in both the email and the message box.',
                    emailMsg2: 'The email address entered is invalid, please enter a valid email address.',
                    emailMsg3: 'Please write a longer message (minimum 10 characters required).',
                    emailMsg4: 'Your message has been sent successfully. Thank you for sending me a message. I will try to answer your message as quickly as possible.',
                    emailMsg5: 'Unfortunately, something went wrong, please try again later.',
                    langs1: 'I speak ...',
                    langs2: 'Persian',
                    langs3: 'German',
                    langs4: 'English',
                    hobbies: 'interests and hobbies',
                    toContact: 'Contact',
                    interesting1: 'Swimming',
                    interesting2: 'Traveling',
                    interesting3: 'Learning',
                    interesting4: 'Cook',
                    interesting5: 'Photography',
                    interesting6: 'Filming',
                    interesting7: 'Gym',
                    interesting8: 'Listening to Music',
                    interesting9: 'Cycling',
                }
            },
            fa: {
                translation: {
                    nav1: 'درباره من',
                    nav2: 'مهارت ها',
                    nav3: 'ارتباط با من',
                    menu: 'منو‌سایت',
                    language: 'زبان ها',
                    name: 'من امیرضا هستم',
                    mainSkill: 'توسعه دهنده فرانت‌اند',
                    about_s: 'من ۸ سال است که در زمینه نرم افزار و مدیریت سیستم های نرم افزاری از تحلیل اولیه تا اجرا فعالیت می کنم.',
                    about_m1: 'بیش از هشت سال تجربه حرفه‌ای در زمینه توسعه نرم‌افزار را دارم. تفکر تحلیلی، تمرکز بر نیازهای مشتری و درک عمیق فنی باعث شده‌اند که به یک فرد مسئولیت‌پذیر و متعهد تبدیل شوم.',
                    about_m2: 'همواره به دنبال طراحی و توسعه نرم‌افزار با کیفیت بالا هستم و برای این هدف، یادگیری فناوری‌های جدید را برای بهبود عملکردم در اولویت قرار می‌دهم.',
                    slogan1: 'ایده‌پردازی',
                    slogan2: 'خلاقیت',
                    slogan3: 'پیاده‌سازی',
                    slogan4: 'پشتیبانی',
                    inTouch1: 'اگر ایده ای دارید که می خواهید آن را در نرم افزار پیاده سازی کنید، کافیست باهم یک وعده برای مشاوره داشته باشیم، من راه حل های جذابی برای گسترش تجارت آنلاین به شما ارائه خواهم داد.',
                    inTouch2: 'با من در ارتباط باشید.',
                    inTouch_Subject: 'موضوغ',
                    inTouch_Email: 'ایمیل شما',
                    inTouch_Message: 'متن پیام',
                    inTouch3: 'ارسال',
                    alertTitle1: 'ایمیل ارسال شد',
                    emailMsg1: 'برای ارسال ایمیل، باید هم آدرس ایمیل و هم قسمت پیام را پر کنید.',
                    emailMsg2: 'آدرس ایمیل وارد شده نامعتبر است. لطفا یک آدرس ایمیل معتبر وارد کنید.',
                    emailMsg3: 'لطفا یک پیام طولانی تر بنویسید (حداقل 10 کاراکتر لازم است).',
                    emailMsg4: 'پیام شما با موفقیت ارسال شد. از اینکه برای من پیام ارسال کردید متشکرم و سعی میکنم در اسرع وقت به پیام شما پاسخ دهم.',
                    emailMsg5: 'با عرض پوزش، یک خطا رخ داده است. لطفاً بعداً دوباره امتحان کنید.',
                    langs1: 'زبان‌ها',
                    langs2: 'فارسی',
                    langs3: 'آلمانی',
                    langs4: 'انگلیسی',
                    hobbies: 'علاقه‌مندی‌ها',
                    toContact: 'راه‌های‌ارتباط',
                    interesting1: 'شنا',
                    interesting2: 'سفر',
                    interesting3: 'یادگیری',
                    interesting4: 'آشپزی',
                    interesting5: 'عکاسی',
                    interesting6: 'فیلم‌برداری',
                    interesting7: 'کراسفیت',
                    interesting8: 'موسیقی',
                    interesting9: 'دوچرخه سواری',
                }
            },
        },
    });