import LanguageFloat from "Utils/LanguageComponent/Float";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as icons from 'react-bootstrap-icons';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MenuItems } from "Constants/MenuItems";
import { Chip, createTheme } from "@mui/material";
import LottieLoading from "./LottieLoading";

export default function HeadIndex() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const setShowMenu = (status) => {
    setShow(status);
  }
  const onChangeLanguage = () => {
    setLoading(true);
    setTimeout(() => {
        setLoading(false)
    }, 500);
  }

  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "pink",
          }
        }
      }
    }
  });

  return (
    <>
      {loading && <LottieLoading />}
      <div className="head" aria-label="menu">
          <div className="head-body" aria-label="a9r2a-reza alborzi">
            <div className="title force-en-font">
                <p>a<span>9</span>r<span>2</span>a</p>
            </div>
            <div className="menu-swip">
                  <Button onClick={() => setShowMenu(true)} className="menu-btn" aria-label="toggle-menu">
                    <div>
                      <span className={`${i18n.dir() === 'rtl' ? 'persian-font' : ''}`}>{t('menu')}</span>
                      <icons.List size={22} stroke="#2d1e27"/>
                    </div>
                  </Button>
                  <SwipeableDrawer
                    anchor={'top'}
                    open={show}
                    onClose={() => setShowMenu(false)}
                    onOpen={() => setShowMenu(true)}
                  >
                    <Box
                      sx={{ width: 'auto', bgcolor: 'text.secondary' }}
                      role="presentation"
                      onClick={() => setShowMenu(false)}
                      onKeyDown={() => setShowMenu(false)}
                    >
                      <List className="menu-list-items">
                        {
                          MenuItems.map(item => {
                            if(item.active)
                              return(
                                <a href={item.action} key={`menu-items-${item.title}`}>
                                  <ListItem key={item.title}>
                                    <ListItemButton sx={{borderRadius: '15px', ":hover": { bgcolor: '#6c757d' } }}>
                                      <ListItemIcon>
                                        {item.icon}
                                      </ListItemIcon>
                                      <ListItemText primary={<p className={`${i18n.dir() === 'rtl' ? 'persian-font' : ''}`}>{t(item.title)}</p>} />
                                    </ListItemButton>
                                  </ListItem>
                                </a>
                              )
                          })
                        }
                      </List>
                      <Divider textAlign="left">
                        <Chip 
                          sx={{
                            bgcolor: '#fff',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                          }}
                          label={<p className={`${i18n.dir() === 'rtl' ? 'persian-font' : ''}`} >{t('language')}</p>}
                        />
                      </Divider>
                      <LanguageFloat onChangeLanguage={onChangeLanguage}/>
                    </Box>
                  </SwipeableDrawer>
            </div>
          </div>
      </div>
    </>
  );
}