export const SkillsInfo = [
    {
        id: 1,
        title: "Professional",
        active: true,
        details: [
            {skill: 'React', complete: 80},
            {skill: 'Next', complete: 60},
            {skill: 'Node', complete: 70},
            {skill: 'PWA', complete: 60},
            {skill: 'JavaScript', complete: 90},
            {skill: 'HTML/CSS', complete: 95},
            {skill: 'SCSS', complete: 85},
            {skill: 'UI/UX', complete: 70},
            {skill: 'Bootstrap ', complete: 90},
            {skill: 'MUI ', complete: 75},
            {skill: 'C#.net', complete: 90},
        ],
    },
    {
        id: 2,
        title: "Databases",
        active: true,
        details: [
            {skill: 'SQLServer', complete: 85},
            {skill: 'MySQL', complete: 55},
            {skill: 'PostgreSQL', complete: 65},
            {skill: 'MongoDB', complete: 85},
            {skill: 'Mongoose ODM', complete: 85},
            {skill: 'SqlQueries', complete: 90},
        ],
    },
    {
        id: 3,
        title: "Skills",
        active: true,
        details: [
            {skill: 'Software Analysis', complete: 90},
            {skill: 'Database Analysis', complete: 85},
            {skill: 'GitHub', complete: 90},
            {skill: 'Agile/Scrum', complete: 80},
            {skill: 'Adobe XD', complete: 90},
            {skill: 'Adobe Photoshop', complete: 60},
            {skill: 'Adobe Premiere', complete: 50},
            {skill: 'Adobe AfterEffects', complete: 40},
            {skill: 'MS Word', complete: 90},
            {skill: 'MS PowerPoint', complete: 90},
            {skill: 'MS Excel', complete: 80},
        ],
    },
    {
        id: 4,
        title: "Personality",
        active: true,
        details: [
            {skill: 'Team leadership', complete: 95},
            {skill: 'Sales management ', complete: 80},
            {skill: 'Product marketing', complete: 80},
            {skill: 'Digital marketing', complete: 70},
            {skill: 'Business process improvement', complete: 70},
            {skill: 'Project scheduling', complete: 90},
            {skill: 'Strategic planning', complete: 90},
            {skill: 'Negotiation skills', complete: 80},
            {skill: 'Convert idea into software', complete: 95},
            
        ],
    },
]