import Lottie from "lottie-react";
import loading from 'Utils/Assets/loading/loading.json'

export default function LottieLoading() {
  return (
    <div className="wait-section">
        <div>
            <Lottie animationData={loading} />
        </div>
    </div>
  );
}