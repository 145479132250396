import React, { forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Divider } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({open, handleClose, title, alertMessage}) {
  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
    >
        <Box sx={{ bgcolor: 'text.secondary', color: '#fff', padding: '5px' }}>
            <DialogTitle sx={{ color: '#fff' }}>
                {title}
            </DialogTitle>
            <Divider sx={{ bgcolor: '#fff' }} />
            <DialogContent sx={{ color: '#fff' }}>
                <DialogContentText id="alert-dialog-slide-description" sx={{ color: '#fff' }}>
                    {alertMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{bgcolor:'#495057', color: '#fff', ":hover": { bgcolor: '#6c757d' } }}>
                    OK
                </Button>
            </DialogActions>
        </Box>
    </Dialog>
  );
}