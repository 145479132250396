import { SkillsInfo } from 'Constants/SkillsInfo';

export default function SkillComponentIndex({value}) {
    const skill = SkillsInfo.find(item => item.id === value)
  return (
    <div className='infos'>
      {
        skill.details.map(items => {
          return (
            <div className="each" key={`each-skill-${items.skill}`}>
                <div className="text">
                    <p>{items.skill}</p>
                    <div className="dashed"></div>
                </div>
                <div className="percent">
                    <div style={{width: `${items.complete}%`}}></div>
                </div>
                <div className="percent-number">
                    <p>{items.complete}%</p>
                </div>
            </div>
          )
        })
      }
    </div>
  );
}