import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MenuLanguage } from "Constants/MenuLanguage";

export default function LanguageFloat({onChangeLanguage}) {
    const { t, i18n } = useTranslation();
    const onChange= (lang) => {
        if(lang !== localStorage.getItem("DL")) {
            localStorage.setItem("DL", lang);
            i18n.changeLanguage(lang)
            onChangeLanguage();
        }
    }

    function EachLang ({name, shortName, image, imageAlt }) {
        return(
            <ListItem key={'persian-language'} onClick={() => onChange(shortName)}>
                <ListItemButton sx={{borderRadius: '15px', ":hover": { bgcolor: '#6c757d' } }}>
                    <ListItemIcon>
                        <div className="image">
                            <img src={image} alt={imageAlt}/>
                        </div>
                    </ListItemIcon>
                    <ListItemText primary={<p className={`${i18n.dir() === 'rtl' ? 'persian-font' : ''}`}>{t(name)}</p>} />
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <List className="menu-language-items">
            {
                MenuLanguage.map(item => {
                    if(item.shortName === i18n.language) {
                        return(
                            <div className="w-100 bg-primary">
                                <EachLang
                                    name={item.name}
                                    shortName={item.shortName}
                                    image={item.image}
                                    imageAlt={item.imageAlt}
                                />
                            </div>
                        )
                    }
                })
            }
            {
                MenuLanguage.map(item => {
                    if(item.shortName !== i18n.language) {
                        return(
                            <div className="w-100">
                                <EachLang
                                    name={item.name}
                                    shortName={item.shortName}
                                    image={item.image}
                                    imageAlt={item.imageAlt}
                                />
                            </div>
                        )
                    }
                })
            }
        </List>
    );
}