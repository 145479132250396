import * as icons from 'react-bootstrap-icons';

export const MenuItems = [{
    title: "nav1",
    icon: <icons.PersonBoundingBox />,
    action: '#section-1',
    active: true,
},{
    title: "nav2",
    icon: <icons.GearWideConnected />,
    action: '#section-4',
    active: true,
},{
    title: "nav3",
    icon: <icons.ListStars />,
    action: '#section-5',
    active: true,
}]