import In_Swimming from 'Utils/Assets/interesting/Swimming.png';
import In_Travel from 'Utils/Assets/interesting/Travel.png';
import In_Learning from 'Utils/Assets/interesting/Self-Learning.png';
import In_Cook from 'Utils/Assets/interesting/Cook.png';
import In_Photography from 'Utils/Assets/interesting/Photography.png';
import In_Filming from 'Utils/Assets/interesting/Filming.png';
import In_Gym from 'Utils/Assets/interesting/Gym.png';
import In_Music from 'Utils/Assets/interesting/Music.png';
import In_Bike from 'Utils/Assets/interesting/bike.png';

export const InterestingsInfo = [{
    name: "interesting1",
    image: In_Swimming,
    active: true,
},{
    name: "interesting2",
    image: In_Travel,
    active: true,
},{
    name: "interesting3",
    image: In_Learning,
    active: false,
},{
    name: "interesting4",
    image: In_Cook,
    active: false,
},{
    name: "interesting5",
    image: In_Photography,
    active: true,
},{
    name: "interesting6",
    image: In_Filming,
    active: false,
},{
    name: "interesting7",
    image: In_Gym,
    active: false,
},{
    name: "interesting8",
    image: In_Music,
    active: true,
},{
    name: "interesting9",
    image: In_Bike,
    active: true,
},]