import LottieLoading from "Screens/Components/LottieLoading";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageComponentIndex({name, image, imageAlt, icon, link}) {
    const [loading, setLoading] = useState(false);
    const {i18n } = useTranslation();
    const onChange= () => {
        if(link && link !== localStorage.getItem("DL")) {
            setLoading(true)
            localStorage.setItem("DL", link);
            i18n.changeLanguage(link);
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }
    return (
        <>
            {loading && <LottieLoading />}
            { image
                ? <div className="each">
                    <div className={`image ${link ? 'handC' : ''}`} onClick={onChange}>
                        <img src={image} alt={imageAlt}/>
                    </div>
                    <div className="name">
                        <p>{name}</p>
                    </div>
                </div>
                : <div className="each">
                    <div className="image">
                        {icon}
                    </div>
                    <div className="name">
                        <p>{name}</p>
                    </div>
                </div>
            }
        </>
    );
  }