import { useTranslation } from "react-i18next";
import * as icons from 'react-bootstrap-icons'

export default function SectionThree() {
  const { t, i18n } = useTranslation();
  return (
    <div id="section-3" className="section-3" aria-label="slogan">
      <div className="caret-show caret-up">
        <icons.CaretUp />
      </div>
      <div className="caret-show caret-down">
        <icons.CaretDown />
      </div>

      {/* <div className={`rect ${i18n.dir() === 'rtl' ? 'drtl' : 'dltr'}`}>
        <div></div>
      </div> */}
      <div className="slogan">
        <p>{t("slogan1")}<span>,</span> {t("slogan2")}<span>,</span> {t("slogan3")}<span>,</span> {t("slogan4")}</p>
      </div>
    </div>
  );
}