import React, { useEffect, useState } from 'react';
import * as icons from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { sendEmail } from 'http/ApiServices';
import LottieLoading from './LottieLoading';
import AlertDialog from './AlertDialog';

export default function SectionFive() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const onSendMail = async () => {
    if (email == "" || message == "") {
      setAlertMessage(t('emailMsg1'));
      return;
    } else if(!validateEmail(email)) {
      setAlertMessage(t('emailMsg2'));
      return;
    } else if(message.length < 5) {
      setAlertMessage(t('emailMsg3'));
      return;
    }
    setLoading(true);
    const result = await sendEmail({email, message});
    if(result && result.data.ok) {
      setEmail('');
      setMessage('');
      setAlertMessage(t('emailMsg4'));
    } else {
      setAlertMessage(t('emailMsg5'));
    }
    setLoading(false);
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
  useEffect(() => {
    if(alertMessage.length > 0) {
      setOpen(true);
    }
  },[alertMessage])
  const handleClose = () => {
    setAlertMessage("");
    setOpen(false);
  }
  return (
    <>
      {loading && <LottieLoading />}
      <div id="section-5" className="section-5" aria-label='contact'>
        <div className="back-image"></div>
        <div className="back-blur"></div>
        <div className="send-mail">
          <div>
            <p>{t("inTouch1")} <icons.PatchCheck className="mb-1" size={20} stroke='#EA738D'/></p>
            <br/>
            <p>{t("inTouch2")}</p>
          </div>

          <div className='email-form'>
            <input
              id="email"
              type="email"
              name="email"
              aria-label='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("inTouch_Email")}
            />
            <textarea
              id="message"
              name="message"
              aria-label='message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t("inTouch_Message")}
            />
            <input
              type="submit"
              aria-label='submit'
              value={t("inTouch3")}
              onClick={onSendMail}
            />
          </div>
          <AlertDialog
            open={open}
            handleClose={handleClose}
            title={t('alertTitle1')}
            alertMessage={alertMessage}
          />
        </div>
      </div>
    </>
  );
}