import "Styles/index.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import HeadIndex from "./Components/HeadIndex";
import SectionOne from "./Components/Section1";
import SectionTwo from "./Components/Section2";
import SectionThree from "./Components/Section3";
import SectionFour from "./Components/Section4";
import SectionFive from "./Components/Section5";
import SectionSix from "./Components/Section6";
import SectionSeven from "./Components/Section7";
import { useEffect, useState } from "react";
import LottieLoading from "./Components/LottieLoading";
import { useTranslation } from "react-i18next";

export default function App() {
  const [wait, setWait] = useState(true);
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  useEffect(() => {
    setTimeout(() => {
      setWait(false);
    }, 500);
  }, []);

  return (
    <div className={`main ${i18n.dir() === 'rtl' ? 'persian-font' : ''}`} >
      {wait && <LottieLoading />}
      <HeadIndex />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
    </div>
  );
}