import LanguageComponentIndex from 'Utils/LanguageComponent';
import * as icons from 'react-bootstrap-icons';
import { MenuLanguage } from 'Constants/MenuLanguage';
import Img_Iran from 'Utils/Assets/Images/iran.png';
import Img_English from 'Utils/Assets/Images/English.gif';
import Img_Germany from 'Utils/Assets/Images/Germany.png';
import { useTranslation } from 'react-i18next';

export default function SectionSix() {
  const { t } = useTranslation();
  return (
    <div id="section-6" className="section-6" aria-hidden="true">
      <div className="section-body">
        <LanguageComponentIndex
          name={t("langs1")}
          icon={<icons.Translate/>}
        />
        {
          MenuLanguage.map(item => {
            return (
              <LanguageComponentIndex
                name={t(item.name)}
                image={item.image}
                link={item.shortName}
                imageAlt={item.imageAlt}
              />
            )
          })
        }
      </div>
    </div>
  );
}