import * as icons from 'react-bootstrap-icons';

export const ContactInfo = [{
    title: "Mai 02, 1992",
    icon: <icons.Calendar3 />,
    href: null,
    head: true,
    ariaLabel: 'birthday',
    footer: false,
},{
    title: "0098-913-2299-483",
    icon: <icons.Telephone />,
    href: 'tel:00989132299483',
    head: true,
    ariaLabel: 'phone-number',
    footer: true,
},{
    title: "0098-913-2299-483",
    icon: <icons.Whatsapp />,
    href: 'https://wa.me/09132299483',
    head: false,
    ariaLabel: 'whatsapp-number',
    footer: true,
},{
    title: "a9r2a",
    icon: <icons.Telegram />,
    href: 'https://t.me/a9r2a',
    head: false,
    ariaLabel: 'telegram-id',
    footer: true,
},{
    title: "a9r2a.alb@gmail.com",
    icon: <icons.Envelope />,
    href: null,
    head: true,
    ariaLabel: 'gmail',
    footer: true,
},{
    title: "Isfahan, Iran",
    icon: <icons.House />,
    href: null,
    head: true,
    ariaLabel: 'current-location',
    footer: false,
},{
    title: "reza.alborzi",
    icon: <icons.Linkedin />,
    href: 'https://www.linkedin.com/in/reza-alborzi',
    head: false,
    ariaLabel: 'linkedin-id',
    footer: true,
}]