import { useEffect } from 'react';
import InterestingComponentIndex from 'Utils/InterestingComponent';
import { useTranslation } from 'react-i18next';
import { ContactInfo } from 'Constants/ContactInfo';
import { InterestingsInfo } from 'Constants/InterestingsInfo';

export default function SectionSeven() {
  const { t } = useTranslation();
  return (
    <div id="section-7" className="section-7" aria-label='contact'>
      <div className="section-body">
        
        <div className="footer" key={'contact'}>
          <div className="title">
            <p>{t("toContact")}</p>
          </div>
          <div className="contact-info">
            {
              ContactInfo.map(item => {
                if(item.footer)
                  return (
                    <div aria-label={item.ariaLabel} key={`contact-footer-${item.ariaLabel}`} className='force-en-font'>
                      {item.icon}
                      <a href={`${item.href ? item.href : '#section-7'}`}>
                        {item.title}
                      </a>
                    </div>
                  )
              })
            }
          </div>
        </div>

        <div className="footer" key={'hobbies'}>
          <div className="title">
            <p>{t("hobbies")}</p>
          </div>
          <div id="interesting-items" className="interesting-items">
            {
              InterestingsInfo.map(item => {
                if(item.active)
                  return(
                    <InterestingComponentIndex
                      key={`interesting-${t(item.name)}`}
                      text={t(item.name)}
                      image={item.image}
                    />
                  )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}