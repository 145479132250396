import { useTranslation } from 'react-i18next';
import { ContactInfo } from 'Constants/ContactInfo';
import Me from '../../Utils/Assets/Profile/ara_pic.jpg'

export default function SectionOne() {
  const { t } = useTranslation();
  return (
    <div id='section-1' className="section-1" aria-hidden="true">
      <div className="infos">
        <div className="image">
          <img src={Me} alt="It's me"/>
        </div>
        <div className="data">
          <strong>{t("name")}</strong>
          <p>{t("mainSkill")}</p>
          <p>{t("about_s")}</p>
          <div className="contact-info">
            {
              ContactInfo.map(item => {
                if(item.head)
                  return (
                    <div key={`contact-head-${item.ariaLabel}`} className='force-en-font'>
                      {item.icon}
                      {item.title}
                    </div>
                  )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}