import Img_English from 'Utils/Assets/Images/English.gif';
import Img_Germany from 'Utils/Assets/Images/Germany.png';
import Img_Iran from 'Utils/Assets/Images/iran.png';

export const MenuLanguage = [{
    name: "langs2",
    shortName: "fa",
    image: Img_Iran,
    imageAlt: "Iran",
},{
    name: "langs3",
    shortName: "de",
    image: Img_Germany,
    imageAlt: "Deutsch",
},{
    name: "langs4",
    shortName: "en",
    image: Img_English,
    imageAlt: "English",
}]