import * as icons from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export default function SectionTwo() {
  const { t, i18n } = useTranslation();
  return (
    <div id="section-2" className="section-2" aria-hidden="true">
      <div className="about">
        <p className="section-title">{t("nav1")}</p>
        <p className="section-body">
          {i18n.dir() === 'rtl' ? <icons.CaretLeftFill/> : <icons.CaretRightFill/>}{t("about_m1")}
        </p>
        <p className="section-body">
          {i18n.dir() === 'rtl' ? <icons.CaretLeftFill/> : <icons.CaretRightFill/>}{t("about_m2")}
        </p>
      </div>
    </div>
  );
}