import React, { useState } from 'react';
import SkillComponentIndex from 'Utils/SkillComponent';
import { useTranslation } from 'react-i18next';
import { SkillsInfo } from 'Constants/SkillsInfo';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function SectionFour() {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(1);
  
  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <div id="section-4" className="section-4" aria-label='skills-section'>
      <div className='section-body' dir='ltr'>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="skills-tab"
          className='tab-container'
          allowScrollButtonsMobile
          TabIndicatorProps={{style: {
            backgroundColor: '#aa5560ab',
          }}}
        >
          {
            SkillsInfo.map(item => {
              if(item.active)
                return(
                  <Tab
                    key={`tabs-${item.title}`}
                    label={item.title}
                    id={`vertical-tab-${item.id}`}
                    aria-label={`skills-${item.id}`}
                    value={item.id}
                    className={`tabs ${item.id === value ? 'active' : ''}`}
                  />
                )
            })
          }
        </Tabs>
        <SkillComponentIndex value={value} />
      </div>
    </div>
  );
}